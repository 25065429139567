import React, { useState, useEffect, useRef } from 'react';
import './CardList.css';
import SvgIcon from '@mui/material/SvgIcon';
import Stack from '@mui/material/Stack';
import DeckList from './DeckList';
import CheckboxesTags from './CheckboxSelect';
import AdComponent from './AdComponent';
import './TestHands';
import {
    Select, MenuItem, ListItemText, Checkbox, Button, Menu, useMediaQuery,
    Dialog, DialogTitle, DialogContent, DialogActions, IconButton, ListSubheader
} from '@mui/material';
import TestHands from './TestHands';

const CardList = ({ selectedCardType, selectedCards, addToSelectedCards, searchTerm, cardData, selectedColor, setSelectedColor, showModal, selectedModalCard, setSelectedModalCard, openModal, closeModal, onClearButtonClick, onSearch, onColorFilterChange, uniqueColors, handleCategoryFilterChange, selectedCategory, uniqueCategories, selectedType, uniqueTypes, handleTypeFilterChange, searchValue, selectedSet, selectedRarity, handleSetFilterChange, uniqueSets, isFullScreen, selectedSuggestedOption, handleSuggestedOptionChange, handleEnter, isDeckBuilderVisible, handleFullScreen, setFilteredCards, filteredCards, handleAAButton, handleFlipButton, isSmallScreen, ZoomMinus, ZoomPlus, zoomLevel, removeFromSelectedCards, sortedSelectedCards, handleImportButtonClick, handleExportButtonClick, setImportedDecklist, isImportPopupOpen, handleDecklistSubmit, closePopup, handleColorFilterChange, handleClearDeckBuilder, isExportPopupOpen, handleOpenPDFViewer, isSmallerScreen, uniqueRarity, handleRarityChange }) => {


    const sortedUniqueTypes = uniqueTypes.slice().sort((a, b) => a.localeCompare(b));
    const searchInputRef = useRef(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestedOptions, setSuggestedOptions] = useState([]);

    const [isSearchBarFocused, setIsSearchBarFocused] = useState();
    const [imageLoaded, setImageLoaded] = useState(false);
    const [leaderFilterChecked, setIsLeaderFilterChecked] = useState(false);
    const [aaFilterChecked, setisAAFilterChecked] = useState(false);
    const handleLeaderFilter = (event) => {
        setIsLeaderFilterChecked(event.target.checked);
    };
    const handleAAFilter = async (event) => {
        setisAAFilterChecked(event.target.checked);
        const cardList = filteredCards;
        for (const card of cardList) {
            await handleAAButton(card);
        }
    };

    const handleRemoveOne = (card) => {
        removeFromSelectedCards(card, 1);
    };
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 54 * 4.5 + 6,
                width: 150,
            },
        },
    };


    const menuHandler = (type) => {
        if (type === 'zoomin' || type === 'zoomout') {
            if (type === 'zoomin') {
                ZoomPlus(['clImage', 'clcircleButtons', 'cltopButtons', 'clleftButtons', 'clfontSize', 'clsvgSize']);
            }
            else if (type === 'zoomout') {
                ZoomMinus(['clImage', 'clcircleButtons', 'cltopButtons', 'clleftButtons', 'clfontSize', 'clsvgSize']);
            }
        }
        else {
            handleSettingsClose();

            setTimeout(() => {
                switch (type) {
                    case 'pdf':
                        handleOpenPDFViewer();
                        break;
                    case 'view':
                        handleEnter('deckBuilder');
                        break;
                    case 'import':
                        handleImportButtonClick();
                        break;
                    case 'export':
                        handleExportButtonClick();
                        break;
                    case 'delete':
                        handleClearDeckBuilder();
                        break;
                }

            }, 100); // 200 milliseconds delay
        }
    };


    const handleRightClick = (e, card) => {
        if (e.type === 'contextmenu') {
            e.preventDefault();
        }
        if (e.Key) {
            openModal(e);
        }
        else {
            openModal(card);
        }
        console.log(card);
    };


    const handleCardClick = (card) => {
        addToSelectedCards(card);
    };
    const getLeaderOrDigiEggColors = () => {
        const colors = new Set();
        console.log(selectedCards);
        selectedCards.forEach(card => {
            if (card["Category"].toLowerCase() === 'leader' || card["Category"].toLowerCase() === 'digi-egg') {
                card["Color"].forEach(color => colors.add(color));
            }
        });
        return Array.from(colors);
    };

    // Set up suggested options based on selected card type
    useEffect(() => {
        if (selectedCardType.toLowerCase() === "optcg") {
            setSuggestedOptions(['Card Name', 'Effect', 'Type', 'Counter', 'Power', 'Card ID', 'Cost', 'Rarity']);
        } else if (selectedCardType.toLowerCase() === "fusionworld") {
            setSuggestedOptions(['Card Name', 'Effect', 'Type', 'Combo', 'Power', 'Card ID', 'Cost', 'Rarity']);
        } else if (selectedCardType.toLowerCase() === 'digimon') {
            setSuggestedOptions(['Card Name', 'Effect', 'Type', 'DP', 'Card ID', 'Cost', 'Rarity', 'Level', 'Attribute', 'Form']);
        }
        else if (selectedCardType.toLowerCase() === 'unionarena') {
            setSuggestedOptions(['Card Name', 'Effect', 'BP', 'Card ID', 'Cost', 'Rarity', 'Energy Generation']);
        }

    }, [selectedCardType]);


    useEffect(() => {

        console.log(selectedSet);
        const uniqueCardIDs = new Set();

        const filteredByColor = selectedColor.length
            ? cardData.filter((card) => card.Color && card.Color.some((color) => selectedColor.includes(color)))
            : cardData;

        const filteredByCategory = selectedCategory.length
            ? filteredByColor.filter((card) => card.Category && selectedCategory.includes(card.Category))
            : filteredByColor;

        const filteredByType = selectedType.length
            ? filteredByCategory.filter((card) => card.Type && selectedType.some(type => card.Type.includes(type)))
            : filteredByCategory;

        const filteredByRarity = selectedRarity.length
            ? filteredByType.filter((card) => card.Rarity && selectedRarity.includes(card.Rarity))
            : filteredByType;

        const filteredBySet = selectedSet.length
            ? filteredByRarity.filter((card) => {
                // Extract the set identifier from each entry in selectedSet
                const setIdentifiers = selectedSet.map(item => item.split(': ')[1].trim());
                console.log(setIdentifiers);
                // Check if the card's set identifier is in the list of selected identifiers
                return card['Set'] &&
                    card['Set'].length > 0 &&
                    setIdentifiers.includes(card['Set']);
            })
            : filteredByRarity;




        if (leaderFilterChecked && selectedCards.length > 0) {
            const leaderOrDigiEggColors = getLeaderOrDigiEggColors();
            const selectedColors = Array.isArray(leaderOrDigiEggColors) ? leaderOrDigiEggColors : [leaderOrDigiEggColors];
            setSelectedColor(selectedColors);
        }
        else if (leaderFilterChecked && selectedCards.length <= 0) {
            setSelectedColor([]);
        }

        const filteredCards = filteredBySet.filter((card) => {
            let cardID = 0

            if (selectedCardType.toLowerCase() === 'unionarena') {
                cardID = `${card["Key"]}`;

            }
            else {
                cardID = `${card['CARD ID'][0]}-${card['CARD ID'][1]}`;
            }
            const isUniqueCardID = !uniqueCardIDs.has(cardID);
            uniqueCardIDs.add(cardID);

            // Check if the search term contains a keyword indicating the field to search
            let passesSearchTermFilter = false;
            if (searchTerm) {
                console.log(selectedSuggestedOption);
                const field = selectedSuggestedOption[0].toLowerCase(); // Get the field to search
                const keyword = searchTerm.toLowerCase(); // Get the keyword to search for
                console.log(card['Cost']['Generic']);
                // Mapping of fields to conditions
                const fieldConditions = {
                    'effect': () => card['Effect'] && card['Effect'].toLowerCase().includes(keyword),
                    'type': () => card['Type'] && card['Type'].some(type => type.toLowerCase().includes(keyword)),
                    'level': () => card['Level'] &&
                        (typeof card['Level'] === 'string' ?
                            card['Level'].toLowerCase().includes(keyword) :
                            card['Level'] === parseInt(keyword)),
                    'power': () => card['Power'] &&
                        (typeof card['Power'] === 'string' ?
                            card['Power'].toLowerCase().includes(keyword) :
                            card['Power'] === parseInt(keyword)),
                    'dp': () => card['Power'] &&
                        (typeof card['Power'] === 'string' ?
                            card['Power'].toLowerCase().includes(keyword) :
                            card['Power'] === parseInt(keyword)),
                    'bp': () => card['Power'] &&
                        (typeof card['Power'] === 'string' ?
                            card['Power'].toLowerCase().includes(keyword) :
                            card['Power'] === parseInt(keyword)),
                    'cost': () => card['Cost']['Generic'].toString().toLowerCase() === keyword
                        && card['Rarity'] !== "L"
                        && card['Key'] !== "P-047"
                        && card['Key'] !== "P-011",
                    'card id': () => {
                        const fullCardID = `${card['CARD ID'][0]}-${card['CARD ID'][1]}`;
                        return fullCardID.toLowerCase() === keyword ||
                            card['CARD ID'].some(idPart => idPart.toLowerCase() === keyword);
                    },
                    'card name': () => card['Card Name'].toLowerCase().includes(keyword),
                    'combo': () => selectedCardType.toLowerCase() === 'fusionworld' && card['Counter'] &&
                        (typeof card['Counter'] === 'string' ?
                            card['Counter'].toLowerCase().includes(keyword) :
                            card['Counter'] === parseInt(keyword)),
                    'counter': () => selectedCardType.toLowerCase() === 'optcg' && card['Counter'] &&
                        (typeof card['Counter'] === 'string' ?
                            card['Counter'].toLowerCase().includes(keyword) :
                            card['Counter'] === parseInt(keyword)),
                    'attribute': () => card['Attribute'].toLowerCase().includes(keyword),
                    'form': () => card['Form'].toLowerCase().includes(keyword),
                    'rarity': () => card['Rarity'] && card['Rarity'].toLowerCase() === keyword.toLowerCase(),
                    'energy generation': () => card['Cost']['Energy Generation'].toString().toLowerCase() === keyword
                };
                if (!fieldConditions[field]) {
                    return false;
                }

                passesSearchTermFilter = fieldConditions[field]();
            }
            else {
                passesSearchTermFilter = true;
            }
            return isUniqueCardID && passesSearchTermFilter;
        });

        console.log(filteredCards);
        setFilteredCards(filteredCards);

    }, [selectedCardType, cardData, searchTerm, selectedColor, selectedCategory, selectedType, selectedSet, leaderFilterChecked, selectedRarity]);


    const [visibleCards, setVisibleCards] = useState(50); // Initial number of visible cards
    const cardListRef = useRef(null); // Reference to the cards container

    // Function to handle scroll events
    const handleScroll = () => {
        if (
            cardListRef.current &&
            cardListRef.current.getBoundingClientRect().bottom <= window.innerHeight
        ) {
            // User has scrolled to the bottom
            setVisibleCards(prevVisibleCards => prevVisibleCards + 40); // Increase the number of visible cards
        }
    };

    // Add scroll event listener when the component mounts
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [settingsAnchorE1, setSettingsAnchorE1] = useState(null);


    const handleSettingsClick = (event) => {
        setSettingsAnchorE1(event.currentTarget);
    };

    const handleSettingsClose = () => {
        setSettingsAnchorE1(null);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if ((isExportPopupOpen || isImportPopupOpen) && !event.target.closest('.popup')) {
                closePopup();
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isExportPopupOpen, isImportPopupOpen]);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const handleFilterModalOpen = () => {
        setIsFilterModalOpen(true);
    };

    const handleFilterModalClose = () => {
        setIsFilterModalOpen(false);
    };

    const groupedSets = uniqueSets.reduce((acc, set) => {
        const series = cardData.find(card => card.set === set)?.series;
        if (series) {
            if (!acc[series]) {
                acc[series] = [];
            }
            acc[series].push(set);
        }
        return acc;
    }, {});




    return (
        <div className="parent-container">
            {selectedCardType && (
                <div className="sticky-bar">
                    <div className="container StickyBarContainer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Dialog open={isFilterModalOpen} onClose={handleFilterModalClose}>
                            <DialogTitle style={{ color: "#828282", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                Filters  <Button className="filterButton" onClick={onClearButtonClick}>Clear</Button>
                            </DialogTitle>
                            <DialogContent>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={selectedColor}
                                    onChange={handleColorFilterChange}
                                    renderValue={(selected) => selected.length ? selected.join(', ') : 'All Colors'}
                                    className="dropdownFilters"
                                    id="colorFilter"
                                    disabled={leaderFilterChecked}
                                >
                                    {uniqueColors.map((color, index) => (
                                        <MenuItem key={index} value={color}>
                                            <Checkbox checked={selectedColor.indexOf(color) > -1} />
                                            <ListItemText primary={color} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={selectedCategory}
                                    onChange={handleCategoryFilterChange}
                                    renderValue={(selected) => selected.length ? selected.join(', ') : 'All Categories'}
                                    className="dropdownFilters"
                                >
                                    {uniqueCategories.map((category, index) => (
                                        <MenuItem key={index} value={category}>
                                            <Checkbox checked={selectedCategory.indexOf(category) > -1} />
                                            <ListItemText primary={category} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={selectedSet}
                                    onChange={handleSetFilterChange}
                                    renderValue={(selected) => selected.length ? selected.join(', ') : 'All Sets'}
                                    className="dropdownFilters"
                                >
                                    {uniqueSets.map((set, index) => (
                                        <MenuItem key={index} value={set}>
                                            <Checkbox checked={selectedSet.indexOf(set) > -1} />
                                            <ListItemText primary={set} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={selectedRarity}
                                    onChange={handleRarityChange}
                                    renderValue={(selected) => selected.length ? selected.join(', ') : 'All Rarities'}
                                    className="dropdownFilters"
                                >
                                    {uniqueRarity.map((rarity, index) => (
                                        <MenuItem key={index} value={rarity}>
                                            <Checkbox checked={selectedRarity.indexOf(rarity) > -1} />
                                            <ListItemText primary={rarity} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <div style={{ color: "#828282" }}><Checkbox style={{ color: "#828282" }} checked={leaderFilterChecked} onChange={handleLeaderFilter} />Filter by Leader/Egg Color</div>
                                {/*
                                <div style={{ color: "#828282" }}><Checkbox style={{ color: "#828282" }} checked={aaFilterChecked} onChange={handleAAFilter} />Only Alt Arts</div>
                                */}


                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleFilterModalClose}>Close</Button>
                            </DialogActions>
                        </Dialog>
                        {isSmallScreen ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Select
                                    displayEmpty
                                    value={selectedSuggestedOption}
                                    onChange={handleSuggestedOptionChange}
                                    renderValue={(selected) => selected.length ? selected.join(', ') : 'Card Name'}
                                    className="dropdownFiltersSearch"
                                    id="colorFilter"
                                    disabled={leaderFilterChecked}
                                >
                                    {suggestedOptions.map((color, index) => (
                                        <MenuItem key={index} value={color}>
                                            <ListItemText primary={color} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <input
                                    ref={searchInputRef}
                                    id="searchFilter"
                                    type="text"
                                    placeholder="Search..."
                                    onChange={(e) => onSearch(e.target.value)}
                                    value={searchValue}
                                    onFocus={() => setIsSearchBarFocused(true)}
                                />
                            </div>
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Select
                                    displayEmpty
                                    value={selectedSuggestedOption}
                                    onChange={handleSuggestedOptionChange}
                                    renderValue={(selected) => selected.length ? selected.join(', ') : 'Card Name'}
                                    className="dropdownFiltersSearch"
                                    id="colorFilter"

                                >
                                    {suggestedOptions.map((color, index) => (
                                        <MenuItem key={index} value={color}>
                                            <ListItemText primary={color} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <input
                                    ref={searchInputRef}
                                    id="searchFilter"
                                    type="text"
                                    placeholder="Search..."
                                    onChange={(e) => onSearch(e.target.value)}
                                    value={searchValue}
                                    onFocus={() => setIsSearchBarFocused(true)}
                                />
                                <Select
                                    multiple
                                    displayEmpty
                                    value={selectedColor}
                                    onChange={handleColorFilterChange}
                                    renderValue={(selected) => selected.length ? selected.join(', ') : 'All Colors'}
                                    className="dropdownFilters"
                                    id="colorFilter"
                                    disabled={leaderFilterChecked}
                                >
                                    {uniqueColors.map((color, index) => (
                                        <MenuItem key={index} value={color}>
                                            <Checkbox checked={selectedColor.indexOf(color) > -1} />
                                            <ListItemText primary={color} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={selectedCategory}
                                    onChange={handleCategoryFilterChange}
                                    renderValue={(selected) => selected.length ? selected.join(', ') : 'All Categories'}
                                    className="dropdownFilters"
                                >
                                    {uniqueCategories.map((category, index) => (
                                        <MenuItem key={index} value={category}>
                                            <Checkbox checked={selectedCategory.indexOf(category) > -1} />
                                            <ListItemText primary={category} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={selectedSet}
                                    onChange={handleSetFilterChange}
                                    renderValue={(selected) => selected.length ? selected.join(', ') : 'All Sets'}
                                    className="dropdownFilters"
                                >
                                    {uniqueSets.map((set, index) => (
                                        <MenuItem key={index} value={set}>
                                            <Checkbox checked={selectedSet.indexOf(set) > -1} />
                                            <ListItemText primary={set} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Button className="filterButton" onClick={onClearButtonClick}>Clear</Button>
                            </div>
                        )}
                        <div style={{ display: 'flex', alignItems: 'center', height: '50px' }}>
                            <img onClick={handleFilterModalOpen} src='assets/filter.svg' className='settingsLogo' alt="egmanlogo" />


                            <img onClick={() => menuHandler('view')} src='assets/deck2.png' className='settingsLogo' alt="egmanlogo" />
                            <TestHands cards={selectedCards} selectedCardType={selectedCardType}></TestHands>
                            <img src='assets/settings.svg' className='settingsLogo' alt="egmanlogo" onClick={handleSettingsClick} />
                            <Menu
                                anchorEl={settingsAnchorE1}
                                open={Boolean(settingsAnchorE1)}
                                onClose={handleSettingsClose}
                            >
                                <MenuItem onClick={() => menuHandler('view')}>View Deck</MenuItem>
                                <MenuItem onClick={() => menuHandler('import')}>Import</MenuItem>
                                <MenuItem onClick={() => menuHandler('export')}>Export</MenuItem>
                                <MenuItem onClick={() => menuHandler('zoomin')}>Zoom In</MenuItem>
                                <MenuItem onClick={() => menuHandler('zoomout')}>Zoom Out</MenuItem>
                                {!isSmallScreen ? (
                                    <MenuItem onClick={() => menuHandler('pdf')}>Proxies</MenuItem>
                                ) : null}
                                <MenuItem onClick={() => menuHandler('delete')}>Clear Deck</MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
            )}
            <div className="container" style={{ marginTop: '10px' }}>
                <div className="card-list-container">
                    <div className="cards-wrapper" ref={cardListRef}>
                        {filteredCards.slice(0, visibleCards).map((card) => {
                            // Find the selected card from the selectedCards array
                            const selectedCard = selectedCards.find((selectedCard) => selectedCard['Key'] === card['Key']);
                            // Extract the count from the selected card object or default to 0 if not found
                            const count = selectedCard ? selectedCard.count : 0;

                            return (
                                <div className="deckBuilderList" onContextMenu={(e) => handleRightClick(e, card)}>
                                    <div className="cardContainer">
                                        <div className='cardButtons'>
                                            {imageLoaded && card['Category'] !== 'AP' && card['Category'] !== 'DON' && (
                                                <>
                                                    <div style={{ width: `${(zoomLevel.clcircleButtons)}px`, height: `${(zoomLevel.clcircleButtons)}px`, top: `${(zoomLevel.cltopButtons)}px`, left: `${(zoomLevel.clleftButtons)}px`, fontSize: `${zoomLevel.clfontSize}px` }} className={card.count > 4 ? 'cardCount exceeded-limit' : 'cardCount'}>
                                                        {count}
                                                    </div>
                                                    <div style={{ top: `${(zoomLevel.cltopButtons * 3)}px`, left: `${(zoomLevel.clleftButtons)}px`, fontSize: `${zoomLevel.clsvgSize}em` }} className="addOne" onClick={() => addToSelectedCards(card)}>
                                                        <SvgIcon sx={{ fontSize: 'inherit' }}>
                                                            <svg
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={1.5}
                                                                stroke="currentColor"
                                                            >
                                                                <path d="M7 12h10m-5-5v10"></path>
                                                            </svg>
                                                        </SvgIcon>
                                                    </div>
                                                    {imageLoaded && (card['Key'].endsWith('-F') || card['Key'].endsWith('-B')) && (
                                                        <div style={{ top: `${(zoomLevel.cltopButtons * 11)}px`, left: `${(zoomLevel.clleftButtons)}px`, fontSize: `${zoomLevel.clsvgSize}em` }} className="addOne" onClick={() => handleFlipButton(card)}>
                                                            <SvgIcon sx={{ fontSize: 'inherit' }}>
                                                                <svg
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={1.5}
                                                                    stroke="currentColor"
                                                                >
                                                                    <path d="M21 9H7.5C5.01472 9 3 11.0147 3 13.5C3 15.9853 5.01472 18 7.5 18H12M21 9L17 5M21 9L17 13" />
                                                                </svg>
                                                            </SvgIcon>
                                                        </div>
                                                    )}

                                                    <div style={{ top: `${(zoomLevel.cltopButtons * 5)}px`, left: `${(zoomLevel.clleftButtons)}px`, fontSize: `${zoomLevel.clsvgSize}em` }} className="addOne" onClick={() => handleRemoveOne(card)}>
                                                        <SvgIcon sx={{ fontSize: 'inherit' }}>
                                                            <svg
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={1.5}
                                                                stroke="currentColor"
                                                            >
                                                                <path d="M7 12h10"></path>
                                                            </svg>
                                                        </SvgIcon>
                                                    </div>
                                                    {imageLoaded && card['Art'] > 0 && (
                                                        <div style={{ top: `${(zoomLevel.cltopButtons * 9)}px`, left: `${(zoomLevel.clleftButtons)}px`, fontSize: `${zoomLevel.clsvgSize}em` }} className="addOne" onClick={() => handleAAButton(card)}>
                                                            <SvgIcon sx={{ fontSize: 'inherit' }}>
                                                                <svg
                                                                    fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor">
                                                                    <polygon id="primary" points="12 4 9.22 9.27 3 10.11 7.5 14.21 6.44 20 12 17.27 17.56 20 16.5 14.21 21 10.11 14.78 9.27 12 4"></polygon>

                                                                </svg>
                                                            </SvgIcon>
                                                        </div>
                                                    )}

                                                </>
                                            )}
                                            <div style={{ top: `${(zoomLevel.cltopButtons * 7)}px`, left: `${(zoomLevel.clleftButtons)}px`, fontSize: `${zoomLevel.clsvgSize}em` }} className="addOne viewModal" onClick={(e) => handleRightClick(e, card)}>
                                                <SvgIcon sx={{ fontSize: 'inherit' }}>
                                                    <svg
                                                        fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor">
                                                        <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" fill="#0D0D0D" /><path d="M21.894 11.553C19.736 7.236 15.904 5 12 5c-3.903 0-7.736 2.236-9.894 6.553a1 1 0 0 0 0 .894C4.264 16.764 8.096 19 12 19c3.903 0 7.736-2.236 9.894-6.553a1 1 0 0 0 0-.894zM12 17c-2.969 0-6.002-1.62-7.87-5C5.998 8.62 9.03 7 12 7c2.969 0 6.002 1.62 7.87 5-1.868 3.38-4.901 5-7.87 5z" fill="#0D0D0D" />

                                                    </svg>
                                                </SvgIcon>
                                            </div>
                                        </div>
                                        <div>
                                            <img
                                                onContextMenu={(e) => handleRightClick(e, card)}
                                                src={`/card_images/${selectedCardType}/${card.ArtSrc ? `${card.ArtSrc}.webp` : `${card.Key}.webp`}`}
                                                alt={card['Card Name']}
                                                tabIndex="-1"
                                                loading="lazy"
                                                onClick={(card['Category'] !== 'AP' && card['Category'] !== 'DON') ? () => handleCardClick(card) : undefined}
                                                onLoad={() => setImageLoaded(true)}
                                                style={{ width: `${zoomLevel.clImage}px` }}
                                                className="CardListImage"
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {selectedCardType && (
                        <DeckList selectedCardType={selectedCardType} cards={sortedSelectedCards} handleRightClick={handleRightClick} handleRemoveOne={handleRemoveOne} handleCardClick={handleCardClick} handleClearDeckBuilder={handleClearDeckBuilder}></DeckList>

                    )}
                </div>
                <AdComponent slotId="be571713-e559-412c-8791-0491550aa02e" type="display" />
                <AdComponent slotId="6c8eb49c-daaa-47e2-a331-99d4a08c834f" type="display" />
                <div className="footer">
                    <ul>The literal and graphical information presented on this site about Dragon Ball Super, Digimon, One Piece, or Battle Spirits Saga  ( including card images and card text) is copyright Bandai Namco Entertainment, Bird Studio/Shueisha and Toei Animation respectively.</ul>

                    This website is not produced by, endorsed by, supported by, or affiliated with Bandai Namco Entertainment, Bird Studio/Shueisha or Toei Animation.

                    <ul>All other content © 2024 Egman Games</ul>
                </div>
            </div >
        </div>
    );
};

export default CardList;
