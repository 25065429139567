import React, { useEffect, useState } from 'react';
import { Page, View, Document, StyleSheet, Text, Image as PDFImage } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
        justifyContent: 'center',
        alignContent: 'center',
        paddingTop: '20px'
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
    },
    imageContainer: {
        width: '63mm',
        height: '88mm',
        position: 'relative',
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: '10px'
    },
    textOverlay: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        color: 'white',
        fontSize: 32,
        textAlign: 'center',
    },
});

// Helper function to convert webp to png
const convertWebpToPng = async (webpSrc) => {
    const img = new window.Image();
    img.src = webpSrc;
    await new Promise((resolve) => {
        img.onload = resolve;
    });

    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    return canvas.toDataURL("image/png");
};

// Custom component for Image with text overlay
const ImageWithOverlay = ({ convertedSrc, text }) => (
    <View style={styles.imageContainer}>
        <PDFImage src={convertedSrc} style={styles.image} />
        <Text style={styles.textOverlay}>{text}</Text>
    </View>
);

const PDFDocument = ({ selectedCards, selectedCardType, cardData, convertedImages }) => {
    // Create a new variable to hold modified selectedCards
    let modifiedSelectedCards = [...selectedCards];

    // Check if selectedCardType is "fusionworld"
    if (selectedCardType === "fusionworld") {
        // Iterate over selectedCards to find cards ending with "-F"
        selectedCards.forEach((card) => {
            if (card['Key'].endsWith("-F")) {
                // Find the corresponding "-B" card in fusionworld.json
                const correspondingCard = cardData.find((fusionCard) => fusionCard['Key'] === card['Key'].replace("-F", "-B"));
                // If corresponding card is found, add it to modifiedSelectedCards
                if (correspondingCard) {
                    modifiedSelectedCards.push({ ...correspondingCard, count: 1 });
                }
            }
        });
    }

    // Check if convertedImages exists and is populated
    if (!convertedImages || Object.keys(convertedImages).length === 0) {
        console.error("No converted images available");
        return null; // Return early to avoid rendering invalid data
    }
    const totalCards = modifiedSelectedCards.reduce((acc, card) => acc + card.count, 0);


    return (
        <Document>
            <Page size="Letter" style={styles.page}>
                <View style={styles.row}>
                    {modifiedSelectedCards.map((card, index) => {
                        const cardImage = `${card['Key']}`;
                        const { count } = card;

                        // Check if the image exists in convertedImages
                        if (!convertedImages[cardImage]) {
                            console.error(`Image not found for card: ${cardImage}`);
                            return null;
                        }

                        // Log to ensure card details are correct
                        console.log('Rendering card:', cardImage, 'with count:', count);

                        return (
                            Array.from({ length: count }).map((_, idx) => (
                                <ImageWithOverlay
                                    key={`${cardImage}-${idx}`}
                                    convertedSrc={convertedImages[cardImage]}  // Ensure this is a valid image
                                    text="PROXY"
                                />
                            ))
                        );
                    })}
                </View>
            </Page>
        </Document>
    );
};

export const preparePDFData = async (selectedCards, selectedCardType) => {
    const convertedImages = {};
    for (const card of selectedCards) {
        const cardImage = `/card_images/${selectedCardType}/${card['Key']}.webp`; // Path to WebP images
        try {
            const pngSrc = await convertWebpToPng(cardImage);

            if (pngSrc) {
                convertedImages[card['Key']] = pngSrc; // Only add if conversion was successful
            } else {
                console.error(`Could not convert image for card: ${card['Key']}`);
            }
        } catch (error) {
            console.error(`Error converting image for card ${card['Key']}:`, error);
        }
    }
    return convertedImages;
};


export default PDFDocument;
