import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CardList from './components/CardList';
import DeckBuilder from './components/DeckBuilder';
import NavBar from './components/NavBar';
import './App.css';
import { decodeQueryStringToDeck } from './util';
import CardModal from './components/CardModal';
import DeckList from './components/DeckList';
import ImageExport from './components/ImageExport';
import PDFModal from './components/PDFModal';
import LandingPage from './components/LandingPage'
import {
  Select, MenuItem, ListItemText, Checkbox, Button, Menu, useMediaQuery,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { preparePDFData } from './components/PDFDocument';



const App = () => {
  const [selectedCards, setSelectedCards] = useState([]);
  const [selectedCardType, setSelectedCardType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [cardData, setCardData] = useState([]);

  const [exportedLink, setExportedLink] = useState('');
  const [sortedSelectedCards, setSortedSelectedCards] = useState([]);
  const [importedDecklist, setImportedDecklist] = useState('');

  const [selectedColor, setSelectedColor] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSet, setSelectedSet] = useState([]);
  const [selectedRarity, setSelectedRarity] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [convertedImages, setConvertedImages] = useState(null);


  useEffect(() => {
    // Pre-convert images when component mounts
    const convertImages = async () => {
      const convertedData = await preparePDFData(selectedCards, selectedCardType);
      setConvertedImages(convertedData);
    };
    convertImages();
  }, [selectedCards, selectedCardType]);

  const handleColorFilterChange = (e) => {
    const { target: { value } } = e;
    console.log(typeof value === 'string' ? value.split(',') : value)
    setSelectedColor(typeof value === 'string' ? value.split(',') : value);
  };

  const handleCategoryFilterChange = (e) => {
    const { target: { value } } = e;
    setSelectedCategory(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSetFilterChange = (e) => {
    const { target: { value } } = e;
    setSelectedSet(typeof value === 'string' ? value.split(',') : value);
  };

  const handleRarityChange = (e) => {
    const { target: { value } } = e;
    setSelectedRarity(typeof value === 'string' ? value.split(',') : value);
  };

  const handleTypeFilterChange = (e) => {
    const { target: { value } } = e;
    setSelectedType(typeof value === 'string' ? value.split(',') : value);
  };

  const addToSelectedCards = (cardToAdd) => {
    console.log(sortedSelectedCards);
    // Set the limit for each type
    const limitPerType = {
      'leader': 1,
      'digi-egg': 5,  // Set the limit for the "leader" type
      // Add limits for other types as needed
    };
    // Check if the limit is reached for the card type
    const currentCountForType = selectedCards.reduce((count, card) => {
      console.log(cardToAdd);
      console.log(cardToAdd.Category.toLowerCase());
      return card.Category.toLowerCase() === cardToAdd.Category.toLowerCase() ? count + card.count : count;
    }, 0);

    if (currentCountForType >= limitPerType[cardToAdd.Category.toLowerCase()]) {
      if (cardToAdd.Category.toLowerCase() === 'digi-egg') {
        return;
      }
      if (cardToAdd.Category.toLowerCase() === 'leader' && cardToAdd['Key'] && !cardToAdd['Key'].endsWith('-B')) {
        const existingLeaderIndex = selectedCards.findIndex((c) => c.Category.toLowerCase() === 'leader');
        if (existingLeaderIndex !== -1) {
          // Replace the existing leader card
          setSelectedCards((prevSelectedCards) => {
            const updatedCards = [...prevSelectedCards];
            updatedCards[existingLeaderIndex] = { ...cardToAdd, count: 1, uniqueKey: `${cardToAdd['CARD ID']}-${Date.now()}` };
            return updatedCards;
          });
          return; // Exit the function after replacing the leader
        }
      }
      if (cardToAdd.Category.toLowerCase() === 'leader' && cardToAdd['Key'] && cardToAdd['Key'].endsWith('-B')) {
        const correspondingFrontCardKey = cardToAdd['Key'].replace('-B', '-F');
        const correspondingFrontCard = cardData.find((card) => card['Key'] === correspondingFrontCardKey);

        const existingLeaderIndex = selectedCards.findIndex((c) => c.Category.toLowerCase() === 'leader');
        if (existingLeaderIndex !== -1) {
          // Replace the existing leader card
          setSelectedCards((prevSelectedCards) => {
            const updatedCards = [...prevSelectedCards];
            updatedCards[existingLeaderIndex] = { ...correspondingFrontCard, count: 1, uniqueKey: `${correspondingFrontCard['CARD ID']}-${Date.now()}` };
            return updatedCards;
          });
          return; // Exit the function after replacing the leader
        }
      }

    }
    // If the card being added has a Key that ends with "-B", add the corresponding "-F" card
    if (cardToAdd.Category.toLowerCase() === 'leader' && cardToAdd['Key'] && cardToAdd['Key'].endsWith('-B')) {
      const correspondingFrontCardKey = cardToAdd['Key'].replace('-B', '-F');
      const correspondingFrontCard = cardData.find((card) => card['Key'] === correspondingFrontCardKey);

      if (correspondingFrontCard) {
        // Check if a card with the same CARD ID already exists
        const existingCardIndex = selectedCards.findIndex((c) => c['CARD ID'] === correspondingFrontCard['CARD ID']);

        if (existingCardIndex !== -1) {
          // If the card already exists, increment its count
          setSelectedCards((prevSelectedCards) => {
            const updatedCards = [...prevSelectedCards];
            updatedCards[existingCardIndex].count += 1;
            return updatedCards;
          });
        } else {
          // If the card is not in the selected cards, add it with count 1
          setSelectedCards((prevSelectedCards) => [
            ...prevSelectedCards,
            { ...correspondingFrontCard, count: 1, uniqueKey: `${correspondingFrontCard['CARD ID']}-${Date.now()}` },
          ]);
        }
      }
    } else {
      // If it's not a -B card, proceed to add the card
      // Check if a card with the same CARD ID already exists
      const existingCardIndex = selectedCards.findIndex((c) => c['CARD ID'] === cardToAdd['CARD ID']);

      if (existingCardIndex !== -1) {
        // If the card already exists, increment its count
        setSelectedCards((prevSelectedCards) => {
          const updatedCards = [...prevSelectedCards];
          updatedCards[existingCardIndex].count += 1;
          return updatedCards;
        });
      } else {
        // If the card is not in the selected cards, add it with count 1
        setSelectedCards((prevSelectedCards) => [
          ...prevSelectedCards,
          { ...cardToAdd, count: 1, uniqueKey: `${cardToAdd['CARD ID']}-${Date.now()}` },
        ]);
      }
    }
  };


  const removeFromSelectedCards = (card, countToRemove = 0) => {
    setSelectedCards((prevSelectedCards) => {
      const updatedCards = prevSelectedCards.reduce((acc, c) => {
        if (c['CARD ID'] === card['CARD ID']) {
          const newCount = c.count - countToRemove;
          if (newCount > 0) {
            // If there are still cards remaining, update the count
            acc.push({ ...c, count: newCount, uniqueKey: `${c['CARD ID']}-${Date.now()}` });
          }
        } else {
          // Keep other cards unchanged
          acc.push(c);
        }
        return acc;
      }, []);

      return updatedCards;
    });
  };

  const handleCardTypeChange = (cardType) => {
    setSelectedCardType(cardType);
    setSelectedCards([]);
    handleClearCardList();
  };

  // Function to encode the selected card keys into a string
  const encodeDeckToQueryString = () => {
    const encodedCards = selectedCards.map((card) => `${card['Key']}:${card.count}`);
    const queryString = encodedCards.join(',');
    return queryString;
  };


  // useEffect to parse the URL when the component mounts
  useEffect(() => {
    const pathName = window.location.pathname;
    const cardTypeFromPath = pathName.split('/')[1]; // Assuming the path is in the format '/Deckbuilder-OP' or '/Deckbuilder-FW'

    // Extract the card type from the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const cardTypeFromUrl = urlParams.get('type');

    // Use card type from URL if available, otherwise use card type from path
    const selectedCardType = cardTypeFromUrl || cardTypeFromPath;

    if (selectedCardType) {
      setSelectedCardType(selectedCardType);
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const deckQueryString = urlParams.get('deck');
    const selectedCardTypeFromUrl = urlParams.get('type');

    const fetchData = async () => {
      if (selectedCardTypeFromUrl && deckQueryString) {
        handleClearCardList();
        setSelectedCardType(selectedCardTypeFromUrl);
        try {
          const decodedDeck = await decodeQueryStringToDeck(deckQueryString, selectedCardTypeFromUrl, cardData);
          setSelectedCards(decodedDeck);
          setView('deckBuilder');
        } catch (error) {
          console.error('Error decoding deck:', error);
        }
      }
    };

    fetchData();

  }, [cardData]);

  // Use another useEffect to clear the URL after setSelectedCards has been updated
  useEffect(() => {
    // Clear the URL after cards are set
    const urlParams = new URLSearchParams(window.location.search);
    const deckQueryString = urlParams.get('deck');
    const selectedCardTypeFromUrl = urlParams.get('type');

    if (selectedCardTypeFromUrl && deckQueryString && selectedCards.length > 0) {
      const updatedUrl = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, updatedUrl);
    }
  }, [selectedCards]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedCardType) {
          const response = await fetch(`/data/${selectedCardType.toLowerCase()}.json`);
          console.log('Response status:', response.status);

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          if (data) {
            // Group cards by prefix
            const groupedData = data.reduce((acc, card) => {
              const [prefix] = card["CARD ID"];
              if (!acc[prefix]) {
                acc[prefix] = [];
              }
              acc[prefix].push(card);
              return acc;
            }, {});

            // Sort each group by number
            Object.values(groupedData).forEach(group => {
              group.sort((a, b) => {
                const [, numberA] = a["CARD ID"];
                const [, numberB] = b["CARD ID"];
                return parseInt(numberA) - parseInt(numberB);
              });
            });

            // Flatten the grouped data back into an array
            const sortedData = Object.values(groupedData).flat();
            setSelectedSuggestedOption(['Card Name']);
            setCardData(sortedData);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedCardType]);




  const handleImport = (inputDecklist, selectedCardType) => {
    // Prompt the user for input or use a modal to get the decklist
    if (inputDecklist && selectedCardType) {
      const parsedDeck = parseImportedDecklist(inputDecklist, selectedCardType);
      console.log(parsedDeck);

      // Use .map to find the cardInfo for each cardKey in parsedDeck
      const parsedDeckWithCardInfo = parsedDeck.map(({ count, cardKey }) => {
        const cardInfo = cardData.find((card) => card['Key'] === cardKey);
        console.log(cardInfo);
        return { ...cardInfo, count }
      });

      setSelectedCards(parsedDeckWithCardInfo);

    } else {
      console.log('Invalid decklist or no deck information found.');
    }
  };

  const parseImportedDecklist = (decklistImport, cardType) => {
    const decklist = decklistImport.replace(/ {2,}/g, ' ');

    const lines = decklist.split('\n').map(line => line.trim()); // Trim spaces at the end of each line

    if (cardType === 'fusionworld') {
      //const lines = decklist.split('\n');
      const parsedDeckMap = new Map();

      lines.forEach((line, index) => {
        if (index === 0 && line.trim().startsWith('Name')) {
          // Skip the line if it starts with "Name"
          return;
        }

        if (line.trim() !== '' && !line.trim().startsWith('//')) {
          const parts = line.split(/\s+/);
          let count = 1;
          let cardKey, cardName;

          if (!isNaN(parts[0])) {
            count = parseInt(parts[0], 10);
            cardKey = parts[1];
            cardName = parts.slice(2).join(' ');
          } else {
            cardKey = parts[0] + "-F";
            cardName = parts.slice(1).join(' ');
          }
          // Check if the card key exists in the cardData
          const cardInfo = cardData.find((card) => card['Key'] === cardKey);
          if (!cardInfo) {
            console.log(`Card with key ${cardKey} not found in the card data.`);
            return; // Skip adding this card to the parsed deck
          }

          const exportID = cardName.split('(')[1]?.slice(0, -1);

          if (parsedDeckMap.has(cardKey)) {
            parsedDeckMap.set(cardKey, parsedDeckMap.get(cardKey) + count);
          } else {
            parsedDeckMap.set(cardKey, count);
          }
        }
      });

      const parsedDeck = Array.from(parsedDeckMap, ([cardKey, count]) => ({ cardKey, count }));
      return parsedDeck;
    } else if (cardType === 'optcg') {
      //const lines = decklist.split('\n');
      const parsedDeckMap = new Map(); // Use a map to store card keys and their counts

      lines.forEach((line) => {
        if (line.trim() !== '' && !line.trim().startsWith('//')) {
          let count, cardKey;

          // If there are spaces, split by whitespace to extract count and card key
          if (line.includes(' ')) {
            const parts = line.split(/\s+/);
            count = parseInt(parts[0].trim(), 10);
            cardKey = parts[1].trim();
          } else {
            // If no spaces, split by "x" to separate count and card key
            const [countString, keyPart] = line.split('x');
            count = parseInt(countString.trim(), 10);
            cardKey = keyPart.trim();
          }
          // Check if the card key exists in the cardData
          const cardInfo = cardData.find((card) => card['Key'] === cardKey);
          if (!cardInfo) {
            console.log(`Card with key ${cardKey} not found in the card data.`);
            return; // Skip adding this card to the parsed deck
          }

          // Check if the card key already exists in the map
          if (parsedDeckMap.has(cardKey)) {
            // If the card key exists, increase the count
            parsedDeckMap.set(cardKey, parsedDeckMap.get(cardKey) + count);
          } else {
            // If the card key does not exist, add it to the map with the count
            parsedDeckMap.set(cardKey, count);
          }
        }
      });

      // Convert the map to an array of objects
      const parsedDeck = Array.from(parsedDeckMap, ([cardKey, count]) => ({ cardKey, count }));
      return parsedDeck;
    } else if (cardType === 'digimon') {
      const parsedDeckMap = new Map();

      if (decklistImport.trim().startsWith('[') && decklistImport.trim().endsWith(']')) {
        // Parse the text as a JSON array
        const cardKeysArray = JSON.parse(decklistImport);

        // Process the array format
        cardKeysArray.forEach(cardKey => {
          // Log each card key being checked
          console.log(`Checking card key from array: ${cardKey}`);

          // Find the card information in cardData using the cardKey
          const cardInfo = cardData.find(card => card['Key'] === cardKey);

          if (!cardInfo) {
            console.log(`Card with key ${cardKey} not found in the card data.`);
            return; // Skip if the card key doesn't exist in cardData
          }

          // If cardKey is already in parsedDeckMap, increment its count
          parsedDeckMap.set(cardKey, (parsedDeckMap.get(cardKey) || 0) + 1);
        });
      } else {
        // Old format (handling input as text)
        const cardLines = decklistImport.split('\n'); // Split by newline to process each line

        cardLines.forEach(line => {
          const [countStr, ...cardParts] = line.trim().split(/\s+/); // Split by whitespace
          const count = parseInt(countStr, 10); // First part is the count

          // Use a regex to find the card key at the end of the line (e.g., EX2-007, ST13-16)
          const cardKey = cardParts.reverse().find(part => /^[A-Z0-9]+-\d+$/.test(part));

          // Check if the card key exists in the cardData
          const cardInfo = cardData.find((card) => card['Key'] === cardKey);
          if (!cardInfo) {
            console.log(`Card with key ${cardKey} not found in the card data.`);
            return; // Skip adding this card to the parsed deck
          }

          // Add the card and its count to the map
          if (parsedDeckMap.has(cardKey)) {
            parsedDeckMap.set(cardKey, parsedDeckMap.get(cardKey) + count);
          } else {
            parsedDeckMap.set(cardKey, count);
          }
        });
      }

      // Convert the map to an array of objects
      const parsedDeck = Array.from(parsedDeckMap, ([cardKey, count]) => ({ cardKey, count }));
      return parsedDeck;
    }
    else if (cardType === 'gundam') {
      //const lines = decklist.split('\n');
      const parsedDeckMap = new Map(); // Use a map to store card keys and their counts

      lines.forEach((line) => {
        if (line.trim() !== '' && !line.trim().startsWith('//')) {
          let count, cardKey;

          // If there are spaces, split by whitespace to extract count and card key
          if (line.includes(' ')) {
            const parts = line.split(/\s+/);
            count = parseInt(parts[0].trim(), 10);
            cardKey = parts[1].trim();
          } else {
            // If no spaces, split by "x" to separate count and card key
            const [countString, keyPart] = line.split('x');
            count = parseInt(countString.trim(), 10);
            cardKey = keyPart.trim();
          }
          // Check if the card key exists in the cardData
          const cardInfo = cardData.find((card) => card['Key'] === cardKey);
          if (!cardInfo) {
            console.log(`Card with key ${cardKey} not found in the card data.`);
            return; // Skip adding this card to the parsed deck
          }

          // Check if the card key already exists in the map
          if (parsedDeckMap.has(cardKey)) {
            // If the card key exists, increase the count
            parsedDeckMap.set(cardKey, parsedDeckMap.get(cardKey) + count);
          } else {
            // If the card key does not exist, add it to the map with the count
            parsedDeckMap.set(cardKey, count);
          }
        }
      });

      // Convert the map to an array of objects
      const parsedDeck = Array.from(parsedDeckMap, ([cardKey, count]) => ({ cardKey, count }));
      return parsedDeck;
    }


  };


  const handleExport = () => {
    console.log("in handleExport");
    const deckQueryString = encodeDeckToQueryString();

    if (deckQueryString && selectedCardType) {
      const shareableLink = `${window.location.origin}/?deck=${deckQueryString}&type=${selectedCardType}`;
      console.log('Share this link:', shareableLink);
      setExportedLink(shareableLink); // Set the exported link in state
    } else {
      console.log('No cards selected for export.');
    }
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleExportButtonClick = () => {
    // Call the onExport function received as a prop
    console.log("triggering handlExport")
    handleExport();


    //setIsPopupOpen(true);
    setIsExportPopupOpen(true);
    console.log(isPopupOpen);
  };

  const closePopup = () => {
    setIsImportPopupOpen(false);
    setIsExportPopupOpen(false);
    setCopiedToClipboard(false);
  };



  const handleSearch = (term) => {
    setSearchTerm(term);
    setSearchValue(term);
  };

  const handleClearDeckBuilder = () => {
    // Add functionality to clear selected items
    setSelectedCards([]);
  };

  const handleClearCardList = () => {
    setSelectedRarity([]);
    setSelectedSet([]);
    setSelectedColor([]);
    setSearchTerm('');
    setSearchValue('');
    setSelectedCategory([]);
    setSelectedType([]);;
    setSelectedSuggestedOption(['Card Name']);
  }

  const handleDeleteColor = (colorToDelete) => () => {
    setSelectedColor((prevSelectedColor) => prevSelectedColor.filter((color) => color !== colorToDelete));
  };

  const handleDeleteCategory = (categoryToDelete) => () => {
    setSelectedCategory((prevSelectedCategory) => prevSelectedCategory.filter((category) => category !== categoryToDelete));
  };

  const handleDeleteSet = (setToDelete) => () => {
    setSelectedSet((prevSelectedSet) => prevSelectedSet.filter((set) => set !== setToDelete));
  };

  const handleDeleteRarity = (rarityToDelete) => () => {
    setSelectedRarity((prevSelectedRarity) => prevSelectedRarity.filter((rarity) => rarity !== rarityToDelete));
  };





  const uniqueColors = useMemo(() => {
    const colors = new Set();

    cardData.forEach((card) => {
      if (card.Color && Array.isArray(card.Color)) {
        card.Color.forEach((color) => {
          colors.add(color);
        });
      } else if (card.Color && typeof card.Color === 'string') {
        // In case Type is a single string instead of an array
        colors.add(card.Color);
      }
    });

    return Array.from(colors);
  }, [cardData]);


  const uniqueCategories = useMemo(() => {
    const categories = new Set();
    cardData.forEach((card) => {
      categories.add(card.Category);
    });
    return Array.from(categories);
  }, [cardData]);

  const uniqueRarity = useMemo(() => {
    const rarities = new Set();
    cardData.forEach((card) => {
      rarities.add(card.Rarity);
    });
    return Array.from(rarities);
  }, [cardData]);

  const uniqueTypes = useMemo(() => {
    const types = new Set();

    cardData.forEach((card) => {
      if (card.Type && Array.isArray(card.Type)) {
        card.Type.forEach((type) => {
          types.add(type);
        });
      } else if (card.Type && typeof card.Type === 'string') {
        // In case Type is a single string instead of an array
        types.add(card.Type);
      }
    });

    return Array.from(types);
  }, [cardData]);

  const uniqueSets = useMemo(() => {
    const sets = new Set();

    cardData.forEach((card) => {
      if (card['CARD ID'] && Array.isArray(card['CARD ID']) && card['CARD ID'].length > 0 && card['Set'] && typeof card['Set'] === 'string') {
        const setId = card['CARD ID'][0] + ':';
        const setWithId = `${setId} ${card['Set']}`;
        sets.add(setWithId);
      }
    });

    // Convert the Set to an array and sort it
    const sortedSets = Array.from(sets).sort();

    return sortedSets;
  }, [cardData]);






  const [showModal, setShowModal] = useState(false);
  const [selectedModalCard, setSelectedModalCard] = useState(null);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const openModal = (card) => {
    setSelectedModalCard(card);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedModalCard(null);
    setShowModal(false);
  };

  const formatCardExportText = () => {
    const header = "Name (Exported)";

    if (selectedCardType.toLowerCase() === "fusionworld") {
      // Fusion World logic
      const cardText = sortedSelectedCards.map((card) => {
        const isLeader = card["Category"].toLowerCase() === "leader";
        const countText = isLeader ? '' : `${card["count"]} `;
        return `${countText}${card["CARD ID"][0]}-${card["CARD ID"][1]} ${card["Card Name"]}(${card["Export"]})`;
      }).join('\n');

      return `${header}\n${cardText}`;
    } else if (selectedCardType.toLowerCase() === "optcg") {
      // OPTCG logic
      const optcgCardText = sortedSelectedCards.map((card) => {
        return `${card["count"]} ${card["CARD ID"][0]}-${card["CARD ID"][1]} ${card["Card Name"]}`;
      }).join('\n');

      return `${optcgCardText}`;
    } else if (selectedCardType.toLowerCase() === "digimon") {
      // Digimon logic
      const digimonCardText = sortedSelectedCards.map((card) => {
        return `${card["count"]} ${card["Card Name"]} ${card["CARD ID"][0]}-${card["CARD ID"][1]}`;
      }).join('\n');

      return `${digimonCardText}`;
    }
    else if (selectedCardType.toLowerCase() === "gundam") {

      const optcgCardText = sortedSelectedCards.map((card) => {
        return `${card["count"]} ${card["CARD ID"][0]}-${card["CARD ID"][1]} ${card["Card Name"]}`;
      }).join('\n');

      return `${optcgCardText}`;
    } else {
      // Handle other card types or provide a default behavior
      return `${header}\nUnsupported card type: ${selectedCardType}`;
    }
  };


  const [activeSection, setActiveSection] = useState('url'); // 'url' or 'text'
  const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);
  const [isImportPopupOpen, setIsImportPopupOpen] = useState(false);

  const toggleSection = (section) => {
    setActiveSection(section);
  };

  const handleCopyToClipboard = () => {
    const textarea = document.getElementById('exportTextArea');

    if (textarea) {
      textarea.select();
      document.execCommand('copy');
      setCopiedToClipboard(true);
    }
  };
  const handleDecklistSubmit = (gameType) => {
    handleImport(importedDecklist, gameType);
    closePopup();
  };

  const handleImportButtonClick = () => {
    setIsImportPopupOpen(true);
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    // Toggle full-screen mode
    setIsFullScreen((prevIsFullScreen) => !prevIsFullScreen);
  };

  const handleFullScreen = (buttonId) => {
    toggleFullScreen();
    /*
    const deckBuilder = document.querySelector('.deck-builder');
    const parentContainer = document.querySelector('.parent-container');
    const deck = document.querySelector('.deck');
    const deckStats = document.querySelector('.deck-stats');

    if (isFullScreen && buttonId === 'buttonCardList') {
      deckBuilder.style.display = 'flex';
      parentContainer.style.width = '55vw';
      deckBuilder.style.width = '42.5vw';
      parentContainer.style.display = 'flex';
      deck.style.flexDirection = 'column'
      deck.style.alignItems = 'center';
      deckStats.style.width = '95%';

    } else if (!isFullScreen && buttonId === 'buttonCardList') {
      deckBuilder.style.display = 'none';
      parentContainer.style.width = '100vw';
      parentContainer.style.display = 'flex';
      deckStats.style.width = '30%';
      setIsDeckBuilderVisible(true);

    }

    if (isFullScreen && buttonId === 'buttonDeckBuilder') {
      deckBuilder.style.display = 'flex';
      parentContainer.style.width = '55vw';
      deckBuilder.style.width = '42.5vw';
      parentContainer.style.display = 'flex';
      deck.style.flexDirection = 'column'
      deck.style.alignItems = 'center';
      deckStats.style.width = '95%';

    }

    else if (!isFullScreen && buttonId === 'buttonDeckBuilder') {
      deckBuilder.style.display = 'flex';
      deckBuilder.style.width = '95vw';
      parentContainer.style.display = 'none';
      deck.style.flexDirection = 'row'
      deck.style.alignItems = 'flex-start';
      deckStats.style.width = '30%';
      setIsDeckBuilderVisible(false);
    }
    */
  }

  const [zoomLevel, setZoomLevel] = useState({
    clImage: 250 * 0.85,
    clcircleButtons: 32 * 0.85,
    clovalButtons: 40 * 0.85,
    cltopButtons: 20 * 0.85,
    clleftButtons: 210 * 0.85,
    clfontSize: 18 * 0.85,
    clsvgSize: 2 * 0.85,
    dbImage: 250 * 0.85,
    dbcircleButtons: 24 * 0.85,
    dbovalButtons: 40 * 0.85,
    dbtopButtons: 20 * 0.85,
    dbleftButtons: 127 * 0.85,
    dbfontSize: 18 * 0.85,
    dbsvgSize: 1.5 * 0.85,
  });

  const ZoomPlus = (targets) => {
    setZoomLevel(prevZoomLevel => {
      const updatedZoomLevel = { ...prevZoomLevel };
      targets.forEach(target => {
        updatedZoomLevel[target] /= 0.8; // Increase by 10%
      });
      return updatedZoomLevel;
    });
  };
  const ZoomMinus = (targets) => {
    setZoomLevel(prevZoomLevel => {
      const updatedZoomLevel = { ...prevZoomLevel };
      targets.forEach(target => {
        updatedZoomLevel[target] *= 0.8; // Decrease by 10%
      });
      return updatedZoomLevel;
    });
  };


  const [selectedSuggestedOption, setSelectedSuggestedOption] = useState([]);

  // Event handler for suggestion select change
  const handleSuggestedOptionChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedSuggestedOption([selectedOption]); // Trigger search based on the selected option
    handleSearch('');
  };

  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);

  const handleOpenPDFViewer = () => {
    setIsPDFModalOpen(true);
  };

  const handleClosePDFModal = () => {
    setIsPDFModalOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const specificButton = event.target.closest('.viewModal');

      if ((isPDFModalOpen && !event.target.closest('.pdf-modal-content'))) {
        handleClosePDFModal();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showModal, isPDFModalOpen]);


  // State declarations and other hooks...
  const [isDeckBuilderVisible, setIsDeckBuilderVisible] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 769px)');
  const isSmallerScreen = useMediaQuery('(max-width: 450px)');

  // Function to toggle between deck builder and card list
  useEffect(() => {
    const digiEggCounts = {};

    // Count the total number of Digi-Egg cards and track the counts of each individual Digi-Egg card
    selectedCards.forEach(card => {
      if ((card['Category'] || '').toLowerCase() === 'digi-egg') {
        const cardKey = card['Key']; // Assuming 'Key' holds the unique identifier for each card
        digiEggCounts[cardKey] = card['count'] || 1; // Initialize count to 1 if not provided
      }
    });

    // Calculate the total count of Digi-Egg cards
    const totalDigiEggCount = Object.values(digiEggCounts).reduce((total, count) => total + count, 0);

    // If the total count exceeds 5, alert the user and prevent further processing
    if (totalDigiEggCount > 5) {
      alert("You can't add more than 5 Digi-Egg cards in total!");
      return;
    }

    const sortedCards = [...selectedCards].sort((a, b) => {
      // Check if either card has the category "Leader"
      const isALeader = (a['Category'] || '').toLowerCase() === 'leader';
      const isBLeader = (b['Category'] || '').toLowerCase() === 'leader';

      // If one of them is a "Leader", prioritize it
      if (isALeader !== isBLeader) {
        return isALeader ? -1 : 1;
      }

      // If both are "Leader" or both are not, then sort by category first
      const categoryA = (a['Category'] || '').toLowerCase();
      const categoryB = (b['Category'] || '').toLowerCase();
      const categoryComparison = categoryA.localeCompare(categoryB);

      // If categories are different, return the category comparison result
      if (categoryComparison !== 0) {
        return categoryComparison;
      }

      if (selectedCardType.toLowerCase() === 'optcg' || selectedCardType.toLowerCase() === 'fusionworld' || selectedCardType.toLowerCase() === 'unionarena') {
        // Sort by cost for 'optcg' and 'fusionworld'
        const costA = a['Cost']?.['Generic'] || 0;
        const costB = b['Cost']?.['Generic'] || 0;
        return costA - costB;
      } else if (selectedCardType.toLowerCase() === 'digimon' || selectedCardType.toLowerCase() === 'gundam') {
        // Handle both Digimon and Gundam cards with no Level
        const levelA = !a['Level'] || a['Level'] === '-' ? -1 : a['Level'];
        const levelB = !b['Level'] || b['Level'] === '-' ? -1 : b['Level'];
        if (levelA !== levelB) {
          return levelA - levelB;
        } else {
          // Use the same logic for cost
          const costA = a['Cost']?.['Generic'] || 0;
          const costB = b['Cost']?.['Generic'] || 0;
          return costA - costB;
        }
      }

    });
    setSortedSelectedCards(sortedCards);
  }, [selectedCards, setSortedSelectedCards]);

  const getPlaceholder = (cardType) => {
    switch (cardType.toLowerCase()) {
      case 'optcg':
        return (
          `Paste your One Piece decklist here such as:
          1xST01-001
          4xST01-007
          4xST01-006
          4xST01-003
          4xST01-002
          2xST01-011
          4xST01-009
          4xST01-004
          4xST01-005
          4xST01-008
          2xST01-013
          4xST01-010
          2xST01-012
          2xST01-016
          2xST01-014
          2xST01-015
          2xST01-017
          `)
      case 'digimon':
        return (
          `Paste your Digimon decklist here such as:
          // Digimon Deck List 
          4 Koromon ST1-01
          4 Biyomon ST1-02
          4 Agumon ST1-03
          4 Dracomon ST1-04
          4 Birdramon ST1-05
          4 Coredramon ST1-06
          2 Greymon ST1-07
          4 Garudamon ST1-08
          4 MetalGreymon ST1-09
          2 Phoenixmon ST1-10
          2 WarGreymon ST1-11
          4 Tai Kamiya ST1-12
          4 Shadow Wing ST1-13
          4 Starlight Explosion ST1-14
          2 Giga Destroyer ST1-15
          2 Gaia Force ST1-16
          `)
      case 'fusionworld':
        return (
          `Paste your Fusion World decklist here such as:
          Name (Exported)
          FS01-01 Son Goku(2)
          4 FS01-03 Master Roshi(5)
          4 FS01-02 Whis(4)
          4 FS01-10 Tien Shinhan(12)
          2 FS01-04 Krillin(6)
          4 FS01-14 Vegeta(16)
          4 FS01-07 Android 18(9)
          4 FS01-06 Android 17(8)
          2 FS01-12 Beerus(14)
          4 FS01-11 Piccolo(13)
          2 FS01-09 Son Gohan : Adolescence(11)
          4 FS01-13 Frieza(15)
          2 FS01-08 Son Goku(10)
          4 FS01-05 Shin(7)
          2 FS01-15 Kamehameha(17)
          4 FS01-16 God Kamehameha(18)`)
      default:
        return 'Paste your decklist here...';
    }
  };


  const [filteredCards, setFilteredCards] = useState([]);
  const handleAAButton = (card) => {
    console.log(card);

    const maxArtCount = card['Art']; // Assuming card['Art'] represents the maximum art count
    let nextArtCount = 0;

    // Increment the art count if it's less than maxArtCount, otherwise set to undefined
    if (card['ArtSrc']) {
      const currentArtCount = parseInt(card['ArtSrc'].split('-').pop());
      nextArtCount = currentArtCount < maxArtCount ? currentArtCount + 1 : undefined;
    } else {
      nextArtCount = 1; // Start from 1 if no art source exists
    }

    // Generate the next art source or set to undefined
    const nextArtSrc = nextArtCount !== undefined ? `${card['Key']}-${nextArtCount}` : undefined;

    // Update the card object with the next art count and art source
    const updatedCard = { ...card, 'ArtSrc': nextArtSrc };

    // Update the filteredCards state using functional setState
    setFilteredCards((prevFilteredCards) => {
      return prevFilteredCards.map((c) => (c['Key'] === card['Key'] ? updatedCard : c));
    });

    // Update the selected modal card if it matches the card being updated
    if (selectedModalCard && selectedModalCard['Key'] === card['Key']) {
      setSelectedModalCard(updatedCard);
    }
  };



  const handleNextCard = () => {
    console.log("hit");
    let currentCards;
    console.log(view);
    if (view === 'deckBuilder') {
      currentCards = sortedSelectedCards;
    }
    else if (view === 'cardList') {
      currentCards = filteredCards;
    }
    const currentIndex = currentCards.indexOf(selectedModalCard);
    const nextIndex = (currentIndex + 1) % currentCards.length;
    setSelectedModalCard(currentCards[nextIndex]);
  };

  const handlePreviousCard = () => {
    let currentCards;
    console.log(view);
    if (view === 'deckBuilder') {
      currentCards = sortedSelectedCards;
    }
    else if (view === 'cardList') {
      currentCards = filteredCards;
    }
    const currentIndex = currentCards.indexOf(selectedModalCard);
    const prevIndex = (currentIndex - 1 + currentCards.length) % currentCards.length;
    setSelectedModalCard(currentCards[prevIndex]);
  };


  //Flip Card
  const handleFlipButton = (card) => {
    const currentKey = card['Key'];
    // Toggle between '-F' and '-B'
    const updatedKey = currentKey.endsWith('-F') ? currentKey.replace(/-F$/, '-B') : currentKey.replace(/-B$/, '-F');

    // Find the card with the updated key in the original data
    const updatedCard = cardData.find((c) => c['Key'] === updatedKey);
    if (updatedCard) {
      // Update the filteredCards with the card having the updated key
      const updatedCards = filteredCards.map((c) => (c['Key'] === card['Key'] ? updatedCard : c));
      setFilteredCards(updatedCards);

      const updatedSortedCards = sortedSelectedCards.map((c) => (c['Key'] === card['Key'] ? updatedCard : c));
      setSortedSelectedCards(updatedSortedCards);

      // If modal is open, update the selected modal card without closing the modal
      if (selectedModalCard && selectedModalCard['Key'] === card['Key']) {
        setSelectedModalCard(updatedCard);

      }
    }
  };

  const [view, setView] = useState('landing'); // Add this line
  const handleEnter = (view) => {
    setView(view);
  };



  return (
    <div className="app-container">
      <NavBar
        onCardTypeChange={handleCardTypeChange}
        selectedCardType={selectedCardType}
      />
      <div className="content-container">
        {view === 'landing' && <LandingPage onCardTypeChange={handleCardTypeChange} onEnter={handleEnter} setSelectedCardType={setSelectedCardType} />}
        {view === 'deckBuilder' && (
          <DeckBuilder
            selectedCards={selectedCards}
            handleExportButtonClick={handleExportButtonClick}
            removeFromSelectedCards={removeFromSelectedCards}
            handleClearDeckBuilder={handleClearDeckBuilder}
            addToSelectedCards={addToSelectedCards}
            openModal={openModal}
            closeModal={closeModal}
            showModal={showModal}
            selectedModalCard={selectedModalCard}
            cardData={cardData}
            sortedSelectedCards={sortedSelectedCards}
            setSortedSelectedCards={setSortedSelectedCards}
            isExportPopupOpen={isExportPopupOpen}
            activeSection={activeSection}
            toggleSection={toggleSection}
            handleCopyToClipboard={handleCopyToClipboard}
            exportedLink={exportedLink}
            formatCardExportText={formatCardExportText}
            copiedToClipboard={copiedToClipboard}
            isImportPopupOpen={isImportPopupOpen}
            setImportedDecklist={setImportedDecklist}
            handleDecklistSubmit={handleDecklistSubmit}
            closePopup={closePopup}
            handleImportButtonClick={handleImportButtonClick}
            setSelectedCardType={setSelectedCardType}
            handleFullScreen={handleFullScreen}
            ZoomMinus={ZoomMinus}
            ZoomPlus={ZoomPlus}
            zoomLevel={zoomLevel}
            isFullScreen={isFullScreen}
            selectedCardType={selectedCardType}
            handleOpenPDFViewer={handleOpenPDFViewer}
            isPDFModalOpen={isPDFModalOpen}
            handleClosePDFModal={handleClosePDFModal}
            handleEnter={handleEnter}
            handleFlipButton={handleFlipButton}
            isSmallerScreen={isSmallerScreen}
          />
        )}
        {view === 'cardList' && (
          <CardList
            isSmallerScreen={isSmallerScreen}
            setImportedDecklist={setImportedDecklist}
            isExportPopupOpen={isExportPopupOpen}
            handleDecklistSubmit={handleDecklistSubmit}
            handleClearDeckBuilder={handleClearDeckBuilder}
            closePopup={closePopup}
            isImportPopupOpen={isImportPopupOpen}
            handleExportButtonClick={handleExportButtonClick}
            handleImportButtonClick={handleImportButtonClick}
            sortedSelectedCards={sortedSelectedCards}
            uniqueColors={uniqueColors}
            onClearButtonClick={handleClearCardList}
            onSearch={handleSearch}
            selectedCardType={selectedCardType}
            selectedCards={selectedCards}
            addToSelectedCards={addToSelectedCards}
            searchTerm={searchTerm}
            cardData={cardData}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            openModal={openModal}
            closeModal={closeModal}
            showModal={showModal}
            selectedModalCard={selectedModalCard}
            onColorFilterChange={handleColorFilterChange}
            setSelectedModalCard={setSelectedModalCard}
            handleCategoryFilterChange={handleCategoryFilterChange}
            selectedCategory={selectedCategory}
            uniqueCategories={uniqueCategories}
            selectedType={selectedType}
            handleTypeFilterChange={handleTypeFilterChange}
            uniqueTypes={uniqueTypes}
            searchValue={searchValue}
            selectedSet={selectedSet}
            handleSetFilterChange={handleSetFilterChange}
            uniqueSets={uniqueSets}
            handleRarityChange={handleRarityChange}
            selectedRarity={selectedRarity}
            uniqueRarity={uniqueRarity}
            isFullScreen={isFullScreen}
            handleSuggestedOptionChange={handleSuggestedOptionChange}
            selectedSuggestedOption={selectedSuggestedOption}
            handleEnter={handleEnter}
            isDeckBuilderVisible={isDeckBuilderVisible}
            handleFullScreen={handleFullScreen}
            handleAAButton={handleAAButton}
            handleFlipButton={handleFlipButton}
            setFilteredCards={setFilteredCards}
            filteredCards={filteredCards}
            isSmallScreen={isSmallScreen}
            ZoomMinus={ZoomMinus}
            ZoomPlus={ZoomPlus}
            zoomLevel={zoomLevel}
            removeFromSelectedCards={removeFromSelectedCards}
            handleColorFilterChange={handleColorFilterChange}
            handleDeleteSet={handleDeleteSet}
            handleDeleteCategory={handleDeleteCategory}
            handleDeleteColor={handleDeleteColor}
            handleOpenPDFViewer={handleOpenPDFViewer}
          />
        )}
        <CardModal
          showModal={showModal}
          selectedModalCard={selectedModalCard}
          handleAAButton={handleAAButton}
          handleFlipButton={handleFlipButton}
          selectedCardType={selectedCardType}
          isFullScreen={isFullScreen}
          handleClose={closeModal}
          handleNextCard={handleNextCard}
          handlePreviousCard={handlePreviousCard}
          isSmallScreen={isSmallScreen}

        />
        {isExportPopupOpen && (
          <Dialog open={isExportPopupOpen} onClose={closePopup} maxWidth="md" fullWidth style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}>
            <DialogTitle style={{ color: "#dadada", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={closePopup}
                aria-label="close"
                sx={{ position: 'absolute', right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent onClick={(e) => e.stopPropagation()}>
              <div className="accordion">
                <div
                  className={`accordion-section ${activeSection === 'url' ? 'active' : ''}`}
                  onClick={() => toggleSection('url')}
                >
                  <Button className="filterButton">Export URL</Button>
                </div>
                <div
                  className={`accordion-section ${activeSection === 'text' ? 'active' : ''}`}
                  onClick={() => toggleSection('text')}
                >
                  <Button className="filterButton">Export Text</Button>
                </div>
                <div className="accordion-section">
                  <ImageExport className="filterButton" deckLink={exportedLink} selectedCardType={selectedCardType} selectedCards={sortedSelectedCards} />
                </div>
              </div>


              {activeSection === 'url' && (
                <div className="popup-content">
                  <textarea className="exportText" id="exportTextArea" onClick={handleCopyToClipboard} value={exportedLink} />
                </div>
              )}

              {activeSection === 'text' && (
                <div className="popup-content">
                  <textarea className="exportText" id="exportTextArea" onClick={handleCopyToClipboard} value={formatCardExportText()} readOnly />
                </div>
              )}
              <Button className="filterButton" onClick={handleCopyToClipboard}>
                {copiedToClipboard ? 'Copied!' : 'Copy'}
              </Button>
            </DialogContent>
          </Dialog>
        )}

        {isImportPopupOpen && (
          <Dialog open={isImportPopupOpen} onClose={closePopup} maxWidth="md" fullWidth style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}>
            <DialogTitle style={{ color: "#dadada", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={closePopup}
                aria-label="close"
                sx={{ position: 'absolute', right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent onClick={(e) => e.stopPropagation()}>
              <div className="popup-content">
                <textarea
                  className="importText"
                  id="importTextArea"
                  placeholder={getPlaceholder(selectedCardType)}
                  onChange={(e) => setImportedDecklist(e.target.value)}
                />
              </div>
              {selectedCardType === 'optcg' && (
                <Button className="filterButton" onClick={() => { handleDecklistSubmit('optcg'); }}>Import</Button>
              )}
              {selectedCardType === 'fusionworld' && (
                <Button className="filterButton" onClick={() => { handleDecklistSubmit('fusionworld'); }}>Import</Button>
              )}
              {selectedCardType === 'digimon' && (
                <Button className="filterButton" onClick={() => { handleDecklistSubmit('digimon'); }}>Import</Button>
              )}
              {selectedCardType === 'gundam' && (
                <Button className="filterButton" onClick={() => { handleDecklistSubmit('gundam'); }}>Import</Button>
              )}
            </DialogContent>
          </Dialog>
        )}

        {isPDFModalOpen && (
          <PDFModal isOpen={isPDFModalOpen} convertedImages={convertedImages} onClose={handleClosePDFModal} selectedCards={selectedCards} cardData={cardData} selectedCardType={selectedCardType} />
        )}
      </div>
    </div >
  );

};

export default App;
