// LandingPage.js
import React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

const LandingPage = ({ onEnter, setSelectedCardType, onCardTypeChange }) => {

    const handleButtonClick = (cardType, nextView) => {
        switch (cardType) {
            case 'optcg':
                // Your logic for 'optcg'
                break;
            case 'fusionworld':
                // Your logic for 'fusionworld'
                break;
            case 'digimon':
                // Your logic for 'digimon'
                break;
            case 'unionarena':
                // Your logic for 'ua'
                break;
            case 'gundam':
                break;
            default:
                // Default logic if needed
                break;
        }
        onCardTypeChange(cardType);
        setSelectedCardType(cardType);
        onEnter(nextView);
    };

    return (
        <div className="container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="landing-page" style={{ color: '#DADADA', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <h1>Welcome to the Egman Deck Builder!</h1>
                <p>Choose a game below to explore different content</p>
            </div >
            <Box
                component="ul"
                sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', p: 0, m: 0 }}
            >
                <Card
                    component="li"
                    sx={{
                        flexBasis: 'calc(50% - 16px)',
                        minWidth: 0,
                        flexGrow: 1,
                        '&:hover': {
                            border: '3px solid #1E1F21',
                            filter: 'brightness(85%)',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            cursor: 'pointer',
                        },
                    }}
                    onClick={() => handleButtonClick('optcg', 'cardList')}
                >
                    <CardCover>
                        <img
                            src='assets/LandingOnePiece.PNG'
                            className='Landing'
                            alt="One Piece TCG"
                            loading="lazy"
                        />
                    </CardCover>
                    <CardContent style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Typography
                            level="body-lg"
                            fontWeight="lg"
                            textColor="#fff"
                            mt={{ xs: 12, sm: 18 }}
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.6)', // semi-transparent black background
                                padding: '4px 8px',
                                borderRadius: '4px'
                            }}
                        >
                            One Piece
                        </Typography>

                    </CardContent>
                </Card>
                <Card
                    component="li"
                    sx={{
                        flexBasis: 'calc(50% - 16px)',
                        minWidth: 0,
                        flexGrow: 1,
                        '&:hover': {
                            border: '3px solid #1E1F21',
                            filter: 'brightness(85%)',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            cursor: 'pointer',
                        },
                    }}
                    onClick={() => handleButtonClick('fusionworld', 'cardList')}
                >
                    <CardCover>
                        <img
                            src='assets/LandingFusionWorld.PNG'
                            className='Landing'
                            alt="Fusion World"
                            loading="lazy"
                        />
                    </CardCover>
                    <CardContent style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Typography
                            level="body-lg"
                            fontWeight="lg"
                            textColor="#fff"
                            mt={{ xs: 12, sm: 18 }}
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.6)', // semi-transparent black background
                                padding: '4px 8px',
                                borderRadius: '4px'
                            }}
                        >
                            Fusion World
                        </Typography>
                    </CardContent>
                </Card>
                <Card
                    component="li"
                    sx={{
                        flexBasis: 'calc(50% - 16px)',
                        minWidth: 0,
                        flexGrow: 1,
                        '&:hover': {
                            border: '3px solid #1E1F21',
                            filter: 'brightness(85%)',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            cursor: 'pointer',
                        },
                    }}
                    onClick={() => handleButtonClick('digimon', 'cardList')}
                >
                    <CardCover>
                        <img
                            src='assets/LandingDigimon.PNG'
                            className='Landing'
                            alt="Digimon TCG"
                            loading="lazy"
                        />
                    </CardCover>
                    <CardContent style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Typography
                            level="body-lg"
                            fontWeight="lg"
                            textColor="#fff"
                            mt={{ xs: 12, sm: 18 }}
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.6)', // semi-transparent black background
                                padding: '4px 8px',
                                borderRadius: '4px'
                            }}
                        >
                            Digimon
                        </Typography>
                    </CardContent>
                </Card>
                <Card
                    component="li"
                    sx={{
                        flexBasis: 'calc(50% - 16px)',
                        minWidth: 0,
                        flexGrow: 1,
                        '&:hover': {
                            border: '3px solid #1E1F21',
                            filter: 'brightness(85%)',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            cursor: 'pointer',
                        },
                    }}
                    onClick={() => handleButtonClick('unionarena', 'cardList')}
                >
                    <CardCover>
                        <img
                            src='assets/LandingUnionArena.PNG'
                            className='Landing'
                            alt="Union Arena"
                            loading="lazy"
                        />
                    </CardCover>
                    <CardContent style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Typography
                            level="body-lg"
                            fontWeight="lg"
                            textColor="#fff"
                            mt={{ xs: 12, sm: 18 }}
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.6)', // semi-transparent black background
                                padding: '4px 8px',
                                borderRadius: '4px'
                            }}
                        >
                            Union Arena
                        </Typography>
                    </CardContent>
                </Card>
                <Card
                    component="li"
                    sx={{
                        flexBasis: 'calc(50% - 16px)',
                        minWidth: 0,
                        maxWidth: '50%',
                        flexGrow: 1,
                        '&:hover': {
                            border: '3px solid #1E1F21',
                            filter: 'brightness(85%)',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            cursor: 'pointer',
                        },
                    }}
                    onClick={() => handleButtonClick('gundam', 'cardList')}
                >
                    <CardCover>
                        <img
                            src='assets/LandingGundam.png'
                            className='Landing'
                            alt="Gundam"
                            loading="lazy"
                        />
                    </CardCover>
                    <CardContent style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Typography
                            level="body-lg"
                            fontWeight="lg"
                            textColor="#fff"
                            mt={{ xs: 12, sm: 18 }}
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.6)', // semi-transparent black background
                                padding: '4px 8px',
                                borderRadius: '4px'
                            }}
                        >
                            Gundam
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
};

export default LandingPage;
