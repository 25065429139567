import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import PDFDocument from './PDFDocument';
import './PDFModal.css'; // Import your CSS file for modal styling

const PDFModal = ({ isOpen, selectedCards, selectedCardType, cardData, convertedImages }) => {
    return (
        <div className={`pdf-modal ${isOpen ? 'open' : ''}`}>
            <div className="pdf-modal-content">
                <PDFViewer width="100%" height="700">
                    <PDFDocument selectedCards={selectedCards} convertedImages={convertedImages} cardData={cardData} selectedCardType={selectedCardType} />
                </PDFViewer>
            </div>
        </div>
    );
};

export default PDFModal;
